<template>
  <div style="padding-top: 5px">
    <ContractTable />
    <ContractPagination />
  </div>
</template>

<script>
import ContractTable from './ContractTable';
import ContractPagination from './ContractPagination';
export default {
  name: 'ContractContainer',
  components: { ContractPagination, ContractTable },
};
</script>
