<template>
  <div>
    <FilterHeader
      :show-del-btn="showDelBtn"
      @deleteTableData="deleteTableData"
    />
    <TableHeader
      :title="'合同管理'"
      :icon="'el-icon-tickets'"
      @insertCustomer="insertCustomer"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="contractData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="contractData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </TableHeader>
    <Table
      :column-config="columnConfig"
      :table-data="contract_store.contract_info"
      :jump-column="['合同名称']"
      @getDelData="getDelData"
      @editData="editData"
      @updateData="updateData"
      @cellClick="cellClick"
    >
      <div slot="dialog">
        <el-form label-position="right" :model="rowData">
          <el-form-item
            v-for="item in columnConfig"
            :key="item.prop"
            :label="item.label"
            label-width="110px"
            required
            v-show="item.show !== false"
          >
            <el-input v-model="rowData[item.prop]" />
          </el-form-item>
        </el-form>
      </div>
    </Table>
    <el-dialog
      :title="
        isObjEmpty(contract_store.contract_product)
          ? '合同货物'
          : '数据加载中...'
      "
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form
        v-show="isObjEmpty(contract_store.contract_product)"
        label-position="right"
      >
        <el-form-item
          v-for="item in productConfig"
          :key="item.prop"
          :label="item.label"
          label-width="110px"
          required
        >
          <el-input v-model="contract_store.contract_product[item.prop]" />
        </el-form-item>
      </el-form>
      <el-form
        v-if="isObjEmpty(contract_store.contract_product)"
        label-position="right"
      >
        <el-form-item label-width="110px" required label="合同产品">
          <el-select
            style="width: 100%"
            v-model="contract_store.contract_product.product"
            placeholder="请选择"
            value-key="id"
          >
            <el-option
              v-for="item in product_store.product_info"
              :key="item.id"
              :label="item.name"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-for="item in productShowConfig"
          :key="item.prop"
          :label="item.label"
          label-width="110px"
          required
        >
          <el-input
            readonly
            disabled
            v-model="contract_store.contract_product.product[item.prop]"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-show="isObjEmpty(contract_store.contract_product)"
          type="warning"
          @click="deleteContractProduct"
          >删除</el-button
        >
        <el-button
          v-show="isObjEmpty(contract_store.contract_product)"
          type="primary"
          @click="updateContractProduct"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FilterHeader from '../common/FilterHeader';
import TableHeader from '../common/TableHeader';
import Table from '../common/Table';
import {
  DELETE_CONTRACT_INFO,
  DELETE_CONTRACT_PRODUCT,
  GET_CONTRACT_INFO,
  GET_CONTRACT_PRODUCT,
  GET_PRODUCT_INFO,
  UPDATE_CONTRACT_PRODUCT,
  UPDATE_CONTRACT_INFO,
  INSERT_CONTRACT_INFO,
} from '../../store/type';
import { showMsg } from '../../util/msg';
import { mapState } from 'vuex';
import { timeStamp2String } from '../../util/timeStampConvert';

export default {
  name: 'ContractTable',
  components: {
    FilterHeader,
    Table,
    TableHeader,
  },
  data() {
    return {
      columnConfig: [
        { prop: 'name', label: '合同名称' },
        { prop: 'createdAt', label: '创建日期', show: false },
        { prop: 'updatedAt', label: '更新日期' },
        { prop: 'startAt', label: '开始日期' },
        { prop: 'endAt', label: '结束日期' },
        { prop: 'remark', label: '备注' },
        { prop: 'approvalStatus', label: '审批状态' },
        { prop: 'totalPaid', label: '总花费' },
        { prop: 'totalAmount', label: '总数量' },
        { prop: 'discount', label: '折扣' },
      ],
      productConfig: [
        { prop: 'sellPrice', label: '销售价格' },
        { prop: 'amount', label: '货物数量' },
        { prop: 'discount', label: '货物折扣' },
      ],
      productShowConfig: [
        { prop: 'name', label: '商品名称', readonly: true },
        { prop: 'price', label: '商品价格', readonly: true },
        { prop: 'desc', label: '商品描述', readonly: true },
        { prop: 'remark', label: '商品备注', readonly: true },
        { prop: 'unit', label: '计量单位', readonly: true },
      ],
      showDelBtn: false,
      delData: [],
      contractData: {},
      rowData: {},
      dialogVisible: false,
      clickedCellId: undefined,
    };
  },
  methods: {
    formDataInfo(data) {
      return {
        amount: data.amount,
        discount: data.discount,
        sellPrice: data.sellPrice,
        product: data.product.id,
        contract: this.clickedCellId,
        id: data.id,
      };
    },
    formStaffInfo(data) {
      return {
        name: data.name,
        updatedAt: timeStamp2String(data.updatedAt),
        startAt: timeStamp2String(data.startAt),
        endAt: timeStamp2String(data.endAt),
        remark: data.remark,
        approvalStatus: data.approvalStatus,
        totalPaid: data.totalPaid,
        totalAmount: data.totalAmount,
        discount: data.discount,
        id: data.id,
      };
    },
    deleteTableData() {
      this.$store.dispatch(DELETE_CONTRACT_INFO, this.delData);
      // 删除合同时同时删除合同下的产品，这里因为合同和产品id不对应的关系所以删除合同时不真正删除合同下的产品
      // this.$store.dispatch(DELETE_CONTRACT_PRODUCT, this.delData[0]);
      showMsg('success', '删除成功！');
    },
    insertCustomer() {
      this.$store.dispatch(
        INSERT_CONTRACT_INFO,
        this.formStaffInfo(this.contractData),
      );
      showMsg('success', '插入成功！');
    },
    updateData() {
      this.$store.dispatch(
        UPDATE_CONTRACT_INFO,
        this.formStaffInfo(this.rowData),
      );
      showMsg('success', '更新成功！');
    },
    updateContractProduct() {
      this.dialogVisible = false;
      this.$store.dispatch(
        UPDATE_CONTRACT_PRODUCT,
        this.formDataInfo(this.contract_store.contract_product),
      );
      showMsg('success', '保存成功！');
    },
    deleteContractProduct() {
      this.dialogVisible = false;
      this.$store.dispatch(
        DELETE_CONTRACT_PRODUCT,
        this.contract_store.contract_product,
      );
      showMsg('success', '删除产品成功！');
    },
    getDelData(val) {
      this.showDelBtn = val.length > 0;
      this.delData = val;
    },
    editData(data) {
      this.rowData = data;
    },
    cellClick(row) {
      this.dialogVisible = true;
      this.contract_store.contract_product = {};
      this.$store.dispatch(GET_CONTRACT_PRODUCT, {
        id: row.id,
      });
      this.clickedCellId = row.id;
    },
    isObjEmpty(obj) {
      return Object.keys(obj).length;
    },
  },
  computed: {
    ...mapState({
      contract_store: state => state.contract_store,
      product_store: state => state.product_store,
    }),
  },
  created() {
    this.$store.dispatch(GET_CONTRACT_INFO);
    this.$store.dispatch(GET_PRODUCT_INFO);
  },
};
</script>
